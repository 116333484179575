.App {
  text-align: center;
  /* width: 1000px;
  height: 2048px; */
  /* background-image: url('./images/background.jpg'); */
  background-repeat: no-repeat;
  background-position: center top;
}

.MuiToolbar-root {
  /* color: 'green'; */
}

.header {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; 
  background-color: lawngreen;
}

.body1 {
  white-space: pre-line;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;
}

.div {
  white-space: pre-line;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;
}

.btn {background-color: yellow;}

.box {
  width: 600px;
  height: 400px;
  top: 200px;
  background-color: white; 
  border-width: 10px;
  border-color: tomato;
}

.MuiDataGrid-columnHeader {
  fontstyle: bold;
}